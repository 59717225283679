/* You can add global styles to this file, and also import other style files */
// @font-face {
//     font-family: "Avenir";
//     src: url("./assets/fonts/Avenir.ttc") format("ttc");
//     font-style: normal;
// }
@tailwind base;
@tailwind components;
@tailwind utilities;
html,
body {
  margin: 0;
  width: 100%;
  height: 100%;
  font-family: "Avenir", sans-serif;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.1;

  // Variables for the main colors used on the site
  --primary-color: #034ea2;
  --disabled-primary-color: #18477c;
  --secondary-color: #f1f5f9;
  --color-3: #e2e8f0;
  --color-4: #252f3f;
  --color-5: #9fa6b2;
  --color-6: #6b7280;
  --text-color1: #64748b;
  --input-background-color1: #f4f5f7;
  --input-background-color2: #f8f8f8;
  --box-shadow1: 0px 0px 20px rgba(0, 0, 0, 0.1);
  --box-shadow1-hover: 0px 0px 25px rgba(0, 0, 0, 0.12);

  h1,
  h2,
  h3,
  h4,
  h5,
  h5,
  p {
    margin: 0;
  }
}

* {
  font-feature-settings: "lnum" 1;
  box-sizing: border-box !important;
  transition: all ease 0.3s;
  outline: none !important;
  letter-spacing: 0.01em;
  padding: 0;
  margin: 0;
}
.page-content {
  width: 100%;
  min-height: 100%;
  .main-body-content {
    width: 100%;
    padding: 40px 50px;
    @media screen and(max-width:1024px) {
      padding: 40px 80px;
    }
    @media screen and (max-width: 768px) {
      padding: 20px 16px;
    }
  }
}
.full-width {
  width: 100%;
}
.threequarter-width {
  width: 73%;
  @media screen and (max-width: 1024px) {
    width: 48%;
  }
  @media screen and (max-width: 425px) {
    width: 100%;
  }
}
.twothird-width {
  width: 64.6%;
  @media screen and (max-width: 1024px) {
    width: 48%;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}
.half-width {
  width: 48%;
  @media screen and (max-width: 540px) {
    width: 100%;
  }
}
.onethird-width {
  width: 31.3%;
  @media screen and (max-width: 1024px) {
    width: 48%;
  }
  @media screen and (max-width: 425px) {
    width: 100%;
  }
}
.onequarter-width {
  width: 23%;
  @media screen and (max-width: 1024px) {
    width: 48%;
  }
  @media screen and (max-width: 425px) {
    width: 100%;
  }
}
.ladda-label {
  display: flex !important;
}
.button-primary {
  cursor: pointer;
  height: 52px;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  background: var(--primary-color);
  border-radius: 5px;
  border: none;
  color: white;
  font-weight: 600;
  white-space: nowrap;
  transition: all ease 0.3s;
  &:hover {
    transform: scale(1.03);
  }
}
.button-primary2 {
  cursor: pointer;
  height: 52px;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  padding: 6px 20px;
  background: var(--primary-color);
  border-radius: 5px;
  border: none;
  color: white;
  font-weight: 400;
  font-size: 0.9em;
  transition: all ease 0.3s;
  &:hover {
    transform: scale(1.03);
  }
}
.button-primary3 {
  cursor: pointer;
  height: 52px;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  padding: 6px 35px;
  background: var(--primary-color);
  border-radius: 5px;
  border: none;
  color: white;
  font-weight: 800;
  font-size: 0.9em;
  transition: all ease 0.3s;
  &:hover {
    transform: scale(1.03);
  }
}
.button-primary4 {
  cursor: pointer;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  border: solid 1px var(--primary-color);
  border-radius: 20px;
  color: white;
  background: var(--primary-color);
  font-weight: 800;
  font-size: 12px;
  transition: all ease 0.3s;
  &:hover {
    transform: scale(1.03);
  }
}
.button-primary4-outline {
  cursor: pointer;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  border: solid 1px var(--primary-color);
  border-radius: 20px;
  background: white;
  color: var(--primary-color);
  font-weight: 800;
  font-size: 12px;
  transition: all ease 0.3s;
  &:hover {
    transform: scale(1.03);
  }
}
.button-cancel {
  cursor: pointer;
  height: 52px;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  background: var(--input-background-color1);
  border-radius: 5px;
  border: none;
  color: var(--color-6);
  font-weight: 600;
  transition: all ease 0.3s;
  &:hover {
    transform: scale(1.03);
  }
}
.button-danger {
  cursor: pointer;
  height: 52px;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  padding: 6px 20px;
  // background-color: #fdf2f2;
  background: #e02424;
  border-radius: 5px;
  border: none;
  color: white;
  // color: #e02424;
  font-weight: 600;
  white-space: nowrap;
  font-size: 0.9em;
  transition: all ease 0.3s;
  &:hover {
    transform: scale(1.03);
  }
}
.form-body {
  width: 100%;
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  padding: 30px 0;
  .form-box {
    width: 500px;
    background: #ffffff;
    box-shadow: var(--box-shadow1);
    border-radius: 15px;
    padding: 30px 45px 60px 45px;
    @media screen and(max-width:768px) {
      min-width: 0px;
      width: 70%;
    }
    @media screen and(max-width:540px) {
      min-width: 0px;
      width: 100%;
      padding: 30px 14px 60px 14px;
      box-shadow: none;
      border-radius: none;
      background: #ffffff7a;
    }
    .nav {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin-top: 10px;
      margin-bottom: -20px;
      .steps {
        flex-grow: 1;
        color: var(--color-5);
        font-weight: 400;
        font-size: 0.8em;
        .active {
          color: var(--color-4);
          font-weight: 500;
          font-size: 1.5em;
        }
      }
      .nav-link {
        display: flex;
        align-items: center;
        cursor: pointer;
        img {
          width: 14px;
          margin-right: 5px;
        }
        span {
          color: var(--color-5);
          font-weight: 400;
          font-size: 0.9em;
        }
        .link {
          color: var(--primary-color);
          font-weight: 600;
          cursor: pointer;
        }
      }
    }
    .form-heading {
      color: var(--color-4);
      font-weight: 700;
      font-size: 26px;
      margin-top: 45px;
      @media screen and(max-width:768px) {
        font-size: 22px;
      }
    }
    .form-subheading {
      color: var(--color-6);
      margin-top: 15px;
      font-weight: 400;
      font-size: 15px;
      line-height: 1.5;
      @media screen and(max-width:768px) {
        font-size: 14px;
      }
    }
    .form-subheading2 {
      color: var(--color-6);
      margin-top: 20px;
      font-weight: normal;
      font-size: 16px;
      line-height: 1.5;
      text-align: justify;
      @media screen and(max-width:768px) {
        font-size: 14px;
      }
      .extra {
        font-weight: 800;
        color: #374151;
      }
    }
    .form-subheading3 {
      color: var(--color-5);
      margin-top: 20px;
      font-weight: 500;
      font-size: 16px;
      line-height: 1.5;
      text-align: left;
      @media screen and(max-width:768px) {
        font-size: 18px;
      }
    }
    .form-content {
      display: flex;
      flex-flow: column;
      margin: 0px 0 20px 0;
    }
    .select-option {
      width: 100%;
      display: flex;
      flex-flow: column;
      margin: 25px 0;
      .option {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 35px 25px;
        background: #ffffff;
        box-shadow: 0px 4px 30px rgba(104, 117, 130, 0.1);
        border-radius: 15px;
        cursor: pointer;
        margin-bottom: 20px;
        .select-icon-div {
          height: 100%;
          display: flex;
          flex-flow: column;
          justify-content: flex-start;
          margin-right: 20px;
          .select-icon {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            border: solid 2px var(--color-5);
          }
          .select-icon-image {
            width: 40px;
            // border-radius: 50%;
            overflow: hidden;
            img {
              width: 100%;
            }
          }
        }
        .option-texts {
          display: flex;
          flex-flow: column;
          flex-grow: 1;
          align-items: flex-start;
          margin-right: 20px;
          .option-heading {
            font-weight: 800;
            font-size: 1em;
            color: var(--color-4);
          }
          .option-subheading {
            margin-top: 5px;
            font-weight: 500;
            font-size: 0.8em;
            color: var(--color-6);
            line-height: 1.5;
          }
        }
        .arrow-div {
          place-self: center;
          img {
            width: 14px;
          }
          [nz-icon] {
            color: var(--color-6);
          }
        }
      }
      .selected {
        border-radius: 6px;
        background: #f5f9ff !important;
        border: 1px solid #1565d8 !important;
        .select-icon-div {
          .select-icon {
            border: solid 5px var(--primary-color);
          }
        }
      }
    }
    .details-1 {
      display: block;
      text-align: right;
      margin: 14px 0;
      cursor: pointer;
      img {
        width: 15px;
        margin-right: 5px;
      }
      p,
      span {
        color: var(--color-6);
        font-size: 14px;
        font-weight: 600;
        &:hover {
          color: var(--color-4);
        }
      }
      .description {
        color: var(--color-5);
        font-size: 14px;
        font-weight: 500;
        margin-right: 5px;
      }
      .link {
        color: var(--primary-color);
        font-weight: 600;
        cursor: pointer;
      }
    }
    .details-2 {
      display: block;
      text-align: right;
      color: var(--color-5);
      font-size: 14px;
      font-weight: 500;
      margin: 20px 0 0 0;
      .link {
        color: var(--primary-color);
        font-weight: 600;
        cursor: pointer;
      }
      .link2 {
        color: var(--primary-color);
        font-weight: 600;
        cursor: pointer;
      }
    }
    .message {
      width: 100%;
      display: flex;
      flex-flow: column;
      align-items: center;
      padding: 50px 0;
      .status-icon {
        width: 120px;
        img {
          width: 100%;
        }
      }
      .message-heading {
        color: var(--color-4);
        font-weight: 700;
        font-size: 17px;
        margin: 20px 0;
        @media screen and(max-width:768px) {
          font-size: 15px;
        }
      }
      .message-subheading {
        color: var(--color-4);
        font-weight: 500;
        font-size: 14px;
        margin-bottom: 20px;
        @media screen and(max-width:768px) {
          font-size: 12px;
        }
        .account-number {
          color: var(--primary-color);
          font-weight: 600;
        }
      }
    }
    .check-list {
      display: flex;
      flex-flow: column;
      align-items: center;
      padding: 25px 0 10px 0;
      .check-item {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0;
        }
        .tick {
          width: 22px;
          margin-right: 15px;
          img {
            width: 100%;
          }
        }
        .details {
          width: 100%;
          .main {
            font-weight: 500;
            font-size: 15px;
            color: #374151;
            width: 100%;
          }
          .description {
            width: 100%;
            font-weight: 400;
            font-size: 12px;
            color: var(--color-6);
            margin-top: 5px;
            line-height: 1.5;
          }
        }
      }
    }
  }
}
.form-body1 {
  width: 100%;
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  .form-box,
  .form-box1 {
    min-width: 500px;
    background: #ffffff;
    border-radius: 15px;
    width: 60%;
    @media screen and(max-width:768px) {
      min-width: 0px;
      width: 70%;
    }
    @media screen and(max-width:540px) {
      min-width: 0px;
      width: 100%;
    }
    .nav {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      .steps {
        flex-grow: 1;
        color: var(--color-5);
        font-weight: 400;
        font-size: 0.8em;
        .active {
          color: var(--color-4);
          font-weight: 500;
          font-size: 1.5em;
        }
      }
    }
    .form-heading {
      color: var(--color-4);
      font-weight: 700;
      font-size: 26px;
      margin-top: 20px;
      @media screen and(max-width:768px) {
        font-size: 22px;
      }
    }
    .form-subheading {
      color: var(--color-6);
      margin-top: 15px;
      font-weight: 400;
      font-size: 15px;
      @media screen and(max-width:768px) {
        font-size: 14px;
      }
    }
    .form-content {
      display: flex;
      flex-flow: column;
      margin: 15px 0 20px 0;
      .picture {
        position: relative;
        .icon {
          width: 120px;
          height: 120px;
          img {
            width: 100%;
            object-fit: cover;
          }
        }
        .edit {
          position: absolute;
          right: 0;
          bottom: 10px;
          width: 24px;
          height: 24px;
          cursor: pointer;
          img {
            width: 100%;
            object-fit: cover;
          }
        }
      }
      .name {
        margin-top: 25px;
        display: flex;
        align-items: center;
        @media screen and(max-width:540px) {
          flex-flow: column;
          align-items: flex-start;
        }
        .heading {
          font-weight: 800;
          font-size: 28px;
          color: var(--color-4);
          @media screen and(max-width:540px) {
            font-size: 24px;
          }
        }
        .tag {
          background: #ebf5ff;
          border-radius: 5px;
          color: var(--primary-color);
          font-weight: 800;
          font-size: 13px;
          margin-left: 10px;
          padding: 4px 15px;
          @media screen and(max-width:540px) {
            margin: 10px 0 0 0;
          }
        }
      }
    }
    .select-option {
      width: 100%;
      display: flex;
      flex-flow: column;
      margin: 25px 0;
      .option {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 25px;
        background: #ffffff;
        box-shadow: 0px 4px 30px rgba(104, 117, 130, 0.1);
        border-radius: 15px;
        cursor: pointer;
        margin-bottom: 20px;
        .select-icon-div {
          height: 100%;
          display: flex;
          flex-flow: column;
          justify-content: flex-start;
          margin-right: 20px;
          .select-icon {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            border: solid 2px var(--color-5);
          }
        }
        .option-texts {
          display: flex;
          flex-flow: column;
          flex-grow: 1;
          align-items: flex-start;
          margin-right: 20px;
          .option-heading {
            font-weight: 800;
            font-size: 1em;
            color: var(--color-4);
          }
          .option-subheading {
            margin-top: 5px;
            font-weight: 500;
            font-size: 0.8em;
            color: var(--color-6);
          }
        }
        .arrow-div {
          place-self: flex-end;
          img {
            width: 14px;
          }
        }
      }
      .selected {
        border-radius: 6px;
        background: #f5f9ff !important;
        border: 1px solid #1565d8 !important;
        .select-icon-div {
          .select-icon {
            border: solid 5px var(--primary-color);
          }
        }
      }
    }
    .details-1 {
      // display: flex;
      // justify-content: flex-end;
      display: block;
      text-align: right;
      margin: 12px 0;
      cursor: pointer;
      img {
        width: 15px;
        margin-right: 5px;
      }
      p,
      span {
        color: var(--color-6);
        font-size: 14px;
        font-weight: 600;
        &:hover {
          color: var(--color-4);
        }
      }
      .description {
        color: var(--color-5);
        font-size: 14px;
        font-weight: 500;
        margin-right: 5px;
      }
      .link {
        color: var(--primary-color);
        font-weight: 600;
        cursor: pointer;
      }
    }
    .details-2 {
      color: var(--color-5);
      font-size: 14px;
      font-weight: 500;
      margin: 20px 0 0 0;
      display: block;
      text-align: right;
      span {
        color: var(--color-4);
        font-weight: 600;
        cursor: pointer;
      }
      .description {
        color: var(--color-5);
        font-size: 14px;
        font-weight: 500;
        margin-right: 5px;
      }
    }
    .message {
      width: 100%;
      display: flex;
      flex-flow: column;
      align-items: center;
      padding: 50px 0;
      .status-icon {
        width: 120px;
        img {
          width: 100%;
        }
      }
      .message-heading {
        color: var(--color-4);
        font-weight: 700;
        font-size: 17px;
        margin: 20px 0;
        @media screen and(max-width:768px) {
          font-size: 15px;
        }
      }
      .message-subheading {
        color: var(--color-4);
        font-weight: 500;
        font-size: 14px;
        margin-bottom: 20px;
        @media screen and(max-width:768px) {
          font-size: 12px;
        }
        .account-number {
          color: var(--primary-color);
          font-weight: 600;
        }
      }
    }
  }
  .form-box1 {
    width: 100%;
    min-width: 0 !important;
  }
}
.sub-form {
  display: flex;
  align-items: flex-end;
  flex-flow: row wrap;
  justify-content: space-between;
  .sub-form-heading {
    color: var(--color-4);
    font-weight: 600;
    font-size: 16px;
    margin: 30px 0 0 0;
    @media screen and(max-width:768px) {
      font-size: 13px;
    }
  }
}
.upload-box {
  align-items: flex-start !important;
}
.input-box {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  margin-top: 20px;
  label {
    color: var(--color-6);
    font-weight: 600;
    font-size: 0.87em;
    margin-bottom: 8px;
    @media screen and(max-width:768px) {
      font-size: 12px;
    }
    & + input,
    & + textarea {
      box-shadow: 0px 0px 0px 3px transparent;
      border: 1px solid transparent;
      &:focus-within {
        box-shadow: 0px 0px 0px 3px rgba(58, 151, 212, 0.36);
        border: 1px solid #b5c6f3;
      }
    }
  }
  textarea {
    width: 100%;
    height: 95px;
    background: var(--input-background-color1);
    border-radius: 8px;
    border: none;
    padding: 15px;
    font-size: 0.9em;
    font-weight: 400;
    resize: none;
    // &:focus {
    //   font-size: 0.9em !important;
    // }
  }
  .otp,
  .pin {
    width: 100%;
    display: flex;
    justify-content: space-between;
    input {
      width: 52px;
      height: 52px;
      border: none;
      padding: 15px;
      font-size: 0.9em;
      font-weight: 400;
      // &:focus {
      //   font-size: 0.9em !important;
      // }
      @media screen and(max-width:1024px) {
        width: 16.5%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .pin {
    width: auto;
    justify-content: center;
    display: flex;
    gap: 12px;

    input {
      border: solid 1px rgb(220, 220, 220);
      background-color: transparent;
      &:focus {
        border: solid 1px var(--primary-color);
      }
    }
  }
  .extra-info {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    .icon {
      margin-left: 10px;
      cursor: pointer;
      [nz-icon] {
        color: var(--color-6);
      }
    }
  }
  .extra-text {
    font-size: 13px;
    color: var(--color-4);
  }
  .terminal {
    width: 100%;
    padding: 10px;
    border: solid 1px rgb(200, 200, 200);
    background: rgb(245, 245, 245);
    color: var(--color-6);
    font-size: 12px;
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    border-radius: 5px;
    margin: 10px 0;
    .code {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      cursor: pointer;
      &:first-child {
        margin-bottom: 8px;
      }
      .copy {
        width: 15px;
        margin-left: 5px;
        flex-shrink: 0;
        img {
          width: 100%;
        }
      }
    }
    .link {
      color: var(--primary-color);
      text-decoration: underline;
      line-height: 1.5;
      margin-right: 3px;
    }
  }
}

input {
  width: 100%;
  height: 52px;
  background: var(--input-background-color1);
  border-radius: 8px;
  border: none;
  padding: 15px;
  font-size: 14px;
  font-weight: 400;
  &::-webkit-input-placeholder {
    line-height: normal !important;
  }
  // &:focus {
  //   font-size: 14px !important;
  // }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
input,
textarea,
select {
  &::placeholder {
    color: var(--color-6);
    font-size: 13px;
    font-weight: 400;
  }
}

input[type="number"],
input[type="text"],
input[type="email"] {
  box-shadow: 0px 0px 0px 3px transparent;
  border: 1px solid transparent;
}

.search-field {
  position: relative;
  .search-result {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    position: relative;
    width: 100%;
    max-height: 180px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 6px !important;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
      background-color: white !important;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--primary-color) !important;
      outline: 1px solid slategrey !important;
    }
    margin-top: 20px;
    padding: 0 0 10px 0;
    text-align: left;
    list-style-type: none;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 2px;
    outline: none;
    p {
      width: 100%;
      padding: 15px;
      color: var(--color-4);
      font-weight: normal;
      cursor: pointer;
      font-size: 0.8em;
      &:hover {
        background-color: rgb(245, 245, 245);
      }
    }
    .no-result {
      width: 100%;
      padding: 12px;
      color: var(--color-4);
      font-weight: normal;
      font-style: italic;
    }
  }
}
.action-align {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-end;
  margin-top: 40px !important;
}
.align-end {
  justify-content: flex-end;
}
.buttons {
  display: flex;
  @media screen and (max-width: 425px) {
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .action-button {
    padding: 6px 50px;
    @media screen and (max-width: 425px) {
      padding: 6px 0px;
    }
    &:not(:last-child) {
      margin-right: 20px;
      @media screen and (max-width: 425px) {
        margin-right: 0;
        margin-bottom: 20px;
      }
    }
  }
}
.buttons2 {
  display: flex;
  @media screen and (max-width: 540px) {
    width: 100%;
    justify-content: space-between;
    flex-flow: column;
  }
  .action-button {
    padding: 6px 50px;
    @media screen and (max-width: 540px) {
      &:last-child {
        margin-top: 20px;
      }
    }
    @media screen and (max-width: 425px) {
      padding: 6px 0px;
    }
    &:not(:last-child) {
      margin-right: 20px;
    }
  }
}
.select-container {
  position: relative;
  display: inline;
  cursor: pointer;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 0px 0px 3px transparent;
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  background-color: var(--input-background-color1);
  &:focus-within {
    box-shadow: 0px 0px 0px 3px rgba(58, 151, 212, 0.36);
    border: 1px solid #b5c6f3;
  }
  img {
    width: 20px;
    margin-left: 10px;
  }
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 52px;
    padding: 10px 15px;
    font-size: 0.8em;
    border: none;
    background-color: var(--input-background-color1);
    border-radius: 4px;
    cursor: pointer;
    &::-ms-expand {
      display: none;
    }
    &:focus {
      font-size: 0.8em !important;
    }
  }
  &:after {
    content: "";
    width: 8px;
    height: 8px;
    position: absolute;
    pointer-events: none;
    border-right: 2px solid #9fa6b2;
    border-bottom: 2px solid #9fa6b2;
    border-radius: 2px;
    top: 40%;
    right: 20px;
    opacity: 0.5;
    transform: rotate(45deg);
    cursor: pointer;
  }
}
.loading-select-container {
  &:after {
    display: none;
  }
}
.select-container2 {
  position: relative;
  display: inline;
  cursor: pointer;
  overflow: hidden;
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    min-width: 70px;
    height: 30px;
    padding: 0 15px;
    font-size: 0.8em;
    border: none;
    background-color: var(--primary-color);
    border-radius: 50px;
    cursor: pointer;
    color: white;
    &::-ms-expand {
      display: none;
    }
  }
  &:after {
    content: "";
    width: 7px;
    height: 7px;
    position: absolute;
    pointer-events: none;
    border-right: 2px solid white;
    border-bottom: 2px solid white;
    border-radius: 1px;
    top: 30%;
    right: 15px;
    transform: rotate(45deg);
    cursor: pointer;
  }
}
.date-container {
  position: relative;
  border: none;
  background-color: var(--input-background-color1);
  border-radius: 10px;
  cursor: pointer;
  overflow: hidden;
  nz-date-picker,
  nz-range-picker {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    opacity: 1;
    cursor: pointer;
    z-index: 1;
    opacity: 0;
  }
  .inner-container {
    width: 100%;
    display: flex;
    align-items: center;
    overflow: hidden;
    padding: 0 20px 0 0;
    background-color: var(--input-background-color1);
    .icon {
      width: 18px;
      img {
        width: 100%;
      }
    }
  }
  input {
    position: relative;
    width: 100%;
    z-index: 2;
    cursor: pointer;
  }
}
input[type="email"] {
  text-transform: lowercase;
}
.ngx-json-viewer {
  overflow: visible !important;
}
.password-container {
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 8px;
  overflow: hidden;
  padding: 0 20px 0 0;
  background-color: var(--input-background-color1);
  box-shadow: 0px 0px 0px 3px transparent;
  border: 1px solid transparent;
  &:focus-within {
    box-shadow: 0px 0px 0px 3px rgba(58, 151, 212, 0.36);
    border: 1px solid #b5c6f3;
  }
  img {
    width: 15px;
  }
  [nz-icon] {
    font-size: 18px;
    color: var(--color-6);
    cursor: pointer;
  }
  span {
    font-size: 12px;
    font-weight: 500;
    color: var(--color-6);
  }
}
.search-container {
  width: 100%;
  height: 52px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  overflow: hidden;
  background-color: var(--input-background-color1);
  box-shadow: 0px 0px 0px 3px transparent;
  border: 1px solid transparent;
  &:focus-within {
    box-shadow: 0px 0px 0px 3px rgba(58, 151, 212, 0.36);
    border: 1px solid #b5c6f3;
  }
  .icon {
    height: 100%;
    flex-grow: 1;
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 20px;
    padding: 0 15px;
    box-sizing: content-box;
    img {
      width: 100%;
    }
  }
}
.radio-container {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  padding: 10px 0;
  transition: all ease 0.4s !important;
  .radio-item {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    cursor: pointer;
    margin-bottom: 20px;
    .active {
      border: solid 6px var(--primary-color) !important;
    }
    .radio-icon {
      width: 20px;
      height: 20px;
      content: "";
      border: solid 1px #6b7280;
      border-radius: 50%;
      margin-right: 10px;
      transition: all ease 0.3s !important;
      flex-shrink: 0;
    }
    .details {
      .title {
        font-weight: 500;
        font-size: 15px;
        color: #252f3f;
        margin-bottom: 3px;
      }
      .subtitle {
        font-weight: normal;
        font-size: 12px;
        color: #6b7280;
      }
    }
  }
}
.phone-number {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  position: relative;
  background-color: var(--input-background-color1);
  margin-top: 5px;
  border-radius: 8px;
  overflow: hidden;
  height: 52px;
  box-shadow: 0px 0px 0px 3px transparent;
  border: 1px solid transparent;
  &:focus-within {
    box-shadow: 0px 0px 0px 3px rgba(58, 151, 212, 0.36);
    border: 1px solid #b5c6f3;
  }
  .flag {
    display: flex;
    position: relative;
    padding: 12px;
    border-right: solid 1px rgb(210, 210, 210);
    .dropdown-trigger {
      display: flex;
      align-items: center;
      cursor: pointer;
      .flag-icon {
        font-size: 20px;
      }
      [nz-icon] {
        margin-left: 9px;
        font-size: 10px;
        color: var(--primary-color);
      }
    }
    select {
      position: absolute;
      top: 0;
      margin-top: 0;
      height: 100%;
      width: 100%;
      opacity: 0;
      cursor: pointer;
    }
  }
  .code {
    padding: 0 9px;
    font-size: 14px;
    color: rgb(60, 60, 60);
  }
  input {
    margin-top: 0px !important;
    // padding: 15px 0px;
  }
}
.ranges {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--input-background-color1);
  padding: 0 15px;
  height: 52px;
  border-radius: 8px;
  overflow: hidden;
  @media screen and(max-width: 1024px) {
    width: 100%;
  }
  .range {
    position: relative;
    border: none;
    max-width: 40%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    z-index: 2;
    nz-date-picker {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      opacity: 1;
      cursor: pointer;
      z-index: 1;
      opacity: 0;
    }
    span {
      height: 100%;
      background-color: transparent;
      display: flex;
      align-items: center;
      cursor: pointer;
      font-size: 0.78em;
      position: relative;
      z-index: 2;
      margin-right: 15px;
      white-space: nowrap;
      @media screen and(max-width:425px) {
        margin-right: 10px;
      }
    }
    .arrow {
      width: 20px;
      // flex-shrink: 0;
      img {
        width: 100%;
      }
    }
  }
}

.modal {
  width: 100%;
  .modal-container {
    display: flex;
    flex-flow: column;
    align-items: center;
    .close span {
      cursor: pointer;
    }
    .approve-transaction-body {
      width: 500px;
      box-shadow: 0px 4px 30px rgba(104, 117, 130, 0.1);
      border-radius: 10px;
      overflow: hidden;
      position: absolute;
      z-index: 200;
      background-color: white;
      @media screen and(max-width:540px) {
        width: 90vw;
      }
      .top {
        width: 100%;
        background-color: rgb(253, 253, 253);
        padding: 30px;
        .close {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          cursor: pointer;
          span {
            color: #9fa6b2;
            font-size: 20px;
          }
        }
        .detail {
          width: 100%;
          display: flex;
          flex-flow: column;
          align-items: center;
          margin-top: 30px;
          .title {
            font-weight: 500;
            font-size: 16px;
            text-align: center;
            color: var(--primary-color);
          }
          .title2 {
            font-weight: 500;
            font-size: 16px;
            text-align: center;
            color: var(--color-4);
          }
          .amount {
            margin-top: 10px;
            font-weight: 800;
            font-size: 43px;
            color: var(--primary-color);
            @media screen and(max-width:540px) {
              font-size: 30px;
            }
          }
          .description {
            width: 100%;
            text-align: center;
            margin-top: 10px;
            font-weight: normal;
            font-size: 15px;
            color: var(--primary-color);
            @media screen and(max-width:540px) {
              font-size: 12px;
              margin-top: 0;
            }
          }
        }
      }
      .content {
        background-color: white;
        padding: 40px;
        @media screen and(max-width:540px) {
          padding: 30px;
        }
        .item {
          width: 100%;
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          padding: 10px 0;
          .name {
            font-weight: normal;
            font-size: 14px;
            color: var(--color-6);
            width: 50%;
            @media screen and(max-width:540px) {
              font-size: 10px;
            }
          }
          .description {
            width: 50%;
            text-align: right;
            font-weight: 500;
            font-size: 14px;
            color: var(--color-4);
            line-height: 1.2;
            @media screen and(max-width:540px) {
              font-size: 10px;
            }
          }
        }
      }
      .footer {
        padding: 40px;
      }
    }
    .beneficiary-body {
      width: 500px;
      box-shadow: 0px 4px 30px rgba(104, 117, 130, 0.1);
      border-radius: 10px;
      overflow: hidden;
      position: absolute;
      z-index: 200;
      background-color: white;
      @media screen and(max-width:540px) {
        width: 90vw;
      }
      .top {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 30px 30px 0 30px;
        .title {
          color: var(--color-4);
          font-weight: 900;
          font-size: 23px;
          @media screen and(max-width:768px) {
            font-size: 22px;
          }
        }
        .close span {
          font-size: 30px;
        }
      }
      .sub-top {
        width: 100%;
        padding: 0 30px;
        .description {
          font-weight: normal;
          font-size: 14px;
          color: var(--color-6);
          margin-top: 20px;
        }
        input {
          width: 100%;
          height: 52px;
          background: var(--input-background-color1);
          border-radius: 8px;
          border: none;
          padding: 15px;
          font-size: 14px;
          font-weight: 400;
          margin-top: 10px;
          &::-webkit-input-placeholder {
            line-height: normal !important;
          }
        }
      }

      .list {
        display: flex;
        flex-flow: column;
        height: 400px;
        .overflow {
          overflow-y: auto;
          padding: 30px 30px 30px 30px;
          &::-webkit-scrollbar,
          &::-moz-osx-scrollbar {
            width: 6px;
          }

          &::-webkit-scrollbar-track,
          &::-moz-osx-scrollbar-track {
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background-color: white;
          }

          &::-webkit-scrollbar-thumb,
          &::-moz-osx-scrollbar-thumb {
            background-color: var(--primary-color);
            outline: 1px solid slategrey;
          }
        }
        .item {
          background: #ffffff;
          box-sizing: border-box;
          box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.12);
          border-radius: 10px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex-shrink: 0;
          padding: 18px 20px;
          margin-bottom: 30px;
          .logo {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            overflow: hidden;
            flex-shrink: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            @media screen and(max-width: 540px) {
              width: 50px;
              height: 50px;
            }
            @media screen and(max-width: 320px) {
              width: 40px;
              height: 40px;
            }
            img {
              width: 100%;
              // height: 100%;
              object-fit: cover;
            }
          }
          .detail {
            flex-grow: 1;
            display: flex;
            flex-flow: column;
            align-items: flex-start;
            margin-left: 15px;
            cursor: pointer;
            .name {
              width: 100%;
              font-weight: 800;
              font-size: 16px;
              color: var(--color-4);
              @media screen and(max-width: 540px) {
                font-size: 14px;
              }
              @media screen and(max-width: 320px) {
                font-size: 12px;
              }
            }
            .account {
              width: 100%;
              font-weight: normal;
              font-size: 13px;
              color: var(--color-6);
              margin-top: 6px;
              @media screen and(max-width: 540px) {
                font-size: 11px;
              }
              @media screen and(max-width: 320px) {
                font-size: 9px;
              }
            }
          }
          .icon {
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            flex-shrink: 0;
            &:hover {
              background: rgb(240, 240, 240);
            }
            [nz-icon] {
              font-size: 18px;
              color: var(--color-6);
              cursor: pointer;
            }
          }
        }
        .active {
          border: 1px solid #034ea2;
        }
        .line {
          border-bottom: solid 1px var(--input-background-color1);
          width: 100%;
          height: 0;
          content: "";
          margin: 10px 0 30px 0;
        }
      }
    }
    .notification-body {
      width: 630px;
      box-shadow: 0px 4px 30px rgba(104, 117, 130, 0.1);
      border-radius: 10px;
      overflow: hidden;
      position: absolute;
      z-index: 200;
      background-color: white;
      @media screen and(max-width:768px) {
        width: 90vw;
      }
      .top {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 30px 30px 0 30px;
        .title {
          color: var(--color-4);
          font-weight: 900;
          font-size: 23px;
          @media screen and(max-width:768px) {
            font-size: 22px;
          }
        }
        .close span {
          font-size: 30px;
        }
      }
      .content {
        display: flex;
        flex-flow: column;
        align-items: flex-start;
        padding: 0 30px;
        height: 500px;
        overflow-y: scroll;
        &::-webkit-scrollbar {
          width: 6px;
        }

        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          background-color: white;
        }

        &::-webkit-scrollbar-thumb {
          background-color: var(--primary-color);
          outline: 1px solid slategrey;
        }
        @media screen and(max-height:540px) {
          height: 440px;
        }
        .activities-title {
          font-weight: 800;
          font-size: 16px;
          color: #64748b;
          margin: 20px 0;
        }
        .activities-list {
          width: 100%;
          display: flex;
          flex-flow: column;
          margin-top: 0px;

          .activities-item {
            padding: 20px 0;
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            background: #ffffff;
            border-bottom: solid 1px #e2e8f0;
            @media screen and(max-width:540px) {
              align-items: flex-start;
            }
            .status-icon {
              width: 40px;
              flex-shrink: 0;
              @media screen and(max-width:540px) {
                width: 45px;
              }
              img {
                width: 100%;
              }
            }
            .details {
              margin-left: 18px;
              display: flex;
              flex-flow: row wrap;
              align-items: center;
              justify-content: space-between;
              flex-grow: 1;
              @media screen and(max-width:1024px) {
                flex-flow: column;
                align-items: flex-start;
              }
              .left {
                flex-grow: 1;
                flex-shrink: 0;
                max-width: 60%;
                @media screen and(max-width:1024px) {
                  max-width: 100%;
                }
                .item-heading {
                  font-weight: 500;
                  font-size: 14px;
                  color: #374151;
                  @media screen and(max-width:540px) {
                    font-size: 16px;
                  }
                }
                .item-description {
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                  margin-top: 11px;
                  max-width: 100%;
                  @media screen and(max-width:540px) {
                    flex-flow: column;
                    align-items: flex-start;
                  }
                  .initiated {
                    color: var(--primary-color);
                    font-weight: 500;
                    margin-right: 2px;
                    font-size: 11px;
                  }
                  .time {
                    font-weight: 500;
                    font-size: 11px;
                    color: #9fa6b2;
                    margin-left: 2px;
                    padding-left: 7px;
                    border-left: solid 1px rgb(170, 170, 170);
                    @media screen and(max-width:540px) {
                      margin: 10px 0 0 0 !important;
                      padding-left: 0;
                      border-left: 0;
                    }
                  }
                }
              }
              .right {
                flex-grow: 1;
                display: flex;
                align-items: center;
                justify-content: space-between;
                @media screen and(max-width:1024px) {
                  flex-flow: column;
                  align-items: flex-start;
                  margin-top: 15px;
                }
                .amount {
                  color: var(--primary-color);
                  font-weight: 800;
                  font-size: 11px;
                  margin: 0 5px;
                  flex-shrink: 0;
                  @media screen and(max-width:1024px) {
                    margin: 0;
                  }
                }
                .action {
                  padding: 8px;
                  color: var(--primary-color);
                  border: solid 1px var(--primary-color);
                  background-color: transparent;
                  border-radius: 5px;
                  font-weight: 600;
                  font-size: 11px;
                  cursor: pointer;
                  @media screen and(max-width:1024px) {
                    margin-top: 15px;
                  }
                }
              }
            }
          }
        }
      }
    }
    .progress-body {
      width: 600px;
      box-shadow: 0px 4px 30px rgba(104, 117, 130, 0.1);
      border-radius: 10px;
      overflow: hidden;
      position: absolute;
      z-index: 200;
      background-color: white;
      padding: 30px 30px 0 30px;
      @media screen and(max-width:675px) {
        width: 90vw;
      }
      .top {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .title {
          color: var(--color-4);
          font-weight: 900;
          font-size: 20px;
          @media screen and(max-width:768px) {
            font-size: 22px;
          }
        }
      }
      .body {
        width: 100%;
        display: flex;
        flex-flow: column;
        align-items: flex-start;
        margin: 35px 0;
        label {
          font-weight: 700;
          font-size: 12px;
          color: #6b7280;
          margin-bottom: 5px;
          span {
            color: var(--primary-color);
          }
        }
        .progress {
          width: 100%;
          height: 20px;
          background: #f4f5f7;
          border-radius: 15px;
          position: relative;
          transition: all ease 0.4s;
          .bar {
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: var(--primary-color);
            border-radius: 15px;
            transition: all ease 0.4s;
          }
        }
        .green {
          color: green;
          font-weight: 700;
          font-size: 12px;
          margin: 5px 0 0 0;
        }
        .red {
          color: #e02424;
          font-weight: 700;
          font-size: 12px;
          margin: 5px 0 0 0;
        }
      }
      .footer {
        padding-bottom: 40px;
      }
    }
    .form-modal,
    .form-modal-wider,
    .form-modal-widest {
      width: 450px;
      // max-height: 90vh;
      overflow-y: scroll;
      box-shadow: 0px 4px 30px rgba(104, 117, 130, 0.1);
      border-radius: 10px;
      overflow: auto;
      position: absolute;
      z-index: 200;
      background-color: white;
      padding: 30px;
      display: flex;
      flex-flow: column;
      align-items: flex-end;
      @media screen and(max-width:540px) {
        width: 90vw;
        padding: 30px;
      }
      @media screen and(max-width:425px) {
        padding: 20px;
      }
      .top {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px;
        @media screen and(max-width:425px) {
          padding: 0;
        }
        .form-heading {
          color: var(--color-4);
          font-weight: 900;
          font-size: 26px;
          @media screen and(max-width:768px) {
            font-size: 22px;
          }
        }
        .close span {
          font-size: 30px;
        }
      }
      .form-box {
        width: 100%;
        // overflow-y: scroll;
        padding: 0 10px;
        @media screen and(max-width:425px) {
          padding: 0;
        }
        &::-webkit-scrollbar {
          width: 6px;
        }

        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          background-color: white;
        }

        &::-webkit-scrollbar-thumb {
          background-color: var(--primary-color);
          outline: 1px solid slategrey;
        }
        .nav {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          margin-top: 10px;
          margin-bottom: -20px;
          .steps {
            flex-grow: 1;
            color: var(--color-5);
            font-weight: 400;
            font-size: 0.8em;
            .active {
              color: var(--color-4);
              font-weight: 500;
              font-size: 1.5em;
            }
          }
          .nav-link {
            display: flex;
            align-items: center;
            cursor: pointer;
            margin-bottom: 40px;
            img {
              width: 14px;
              margin-right: 5px;
            }
            span {
              color: var(--color-5);
              font-weight: 400;
              font-size: 0.9em;
            }
            .link {
              color: var(--primary-color);
              font-weight: 600;
              cursor: pointer;
            }
          }
        }
        .form-heading {
          color: var(--color-4);
          font-weight: 900;
          font-size: 26px;
          @media screen and(max-width:768px) {
            font-size: 22px;
          }
        }
        .form-subheading {
          color: var(--color-6);
          margin-top: 10px;
          font-weight: 400;
          font-size: 15px;
          @media screen and(max-width:768px) {
            font-size: 14px;
          }
        }
        .form-content {
          display: flex;
          flex-flow: column;
          margin: 15px 0 20px 0;
        }
        .select-option {
          width: 100%;
          display: flex;
          flex-flow: column;
          margin: 25px 0;
          .option {
            width: 100%;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            padding: 35px 25px;
            background: #ffffff;
            box-shadow: 0px 4px 30px rgba(104, 117, 130, 0.1);
            border-radius: 15px;
            cursor: pointer;
            margin-bottom: 20px;
            .select-icon-div {
              height: 100%;
              display: flex;
              flex-flow: column;
              justify-content: flex-start;
              margin-right: 20px;
              .select-icon {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                border: solid 2px var(--color-5);
              }
            }
            .option-texts {
              display: flex;
              flex-flow: column;
              flex-grow: 1;
              align-items: flex-start;
              margin-right: 20px;
              .option-heading {
                font-weight: 800;
                font-size: 1em;
                color: var(--color-4);
              }
              .option-subheading {
                margin-top: 5px;
                font-weight: 500;
                font-size: 0.8em;
                color: var(--color-6);
              }
            }
            .arrow-div {
              place-self: center;
              img {
                width: 14px;
              }
              [nz-icon] {
                color: var(--color-6);
              }
            }
          }
          .selected {
            border-radius: 6px;
            background: #f5f9ff !important;
            border: 1px solid #1565d8 !important;
            .select-icon-div {
              .select-icon {
                border: solid 5px var(--primary-color);
              }
            }
          }
        }
        .details-1 {
          // display: flex;
          // justify-content: flex-end;
          display: block;
          text-align: right;
          margin: 12px 0;
          cursor: pointer;
          img {
            width: 15px;
            margin-right: 5px;
          }
          p,
          span {
            color: var(--color-6);
            font-size: 14px;
            font-weight: 600;
            &:hover {
              color: var(--color-4);
            }
          }
          .link {
            color: var(--primary-color);
            font-weight: 600;
            cursor: pointer;
          }
        }
        .details-2 {
          display: block;
          text-align: right;
          color: var(--color-5);
          font-size: 14px;
          font-weight: 500;
          margin: 20px 0 0 0;
          .span {
            color: var(--color-4);
            font-weight: 600;
            cursor: pointer;
          }
          .link {
            color: var(--primary-color);
            font-weight: 600;
            cursor: pointer;
          }
        }
        .message {
          width: 100%;
          display: flex;
          flex-flow: column;
          align-items: center;
          padding: 50px 0;
          .status-icon {
            width: 120px;
            img {
              width: 100%;
            }
          }
          .message-heading {
            color: var(--color-4);
            font-weight: 700;
            font-size: 17px;
            margin: 20px 0;
            @media screen and(max-width:768px) {
              font-size: 15px;
            }
          }
          .message-subheading {
            color: var(--color-4);
            font-weight: 500;
            font-size: 14px;
            margin-bottom: 20px;
            @media screen and(max-width:768px) {
              font-size: 12px;
            }
            .account-number {
              color: var(--primary-color);
              font-weight: 600;
            }
          }
        }
      }
      .content {
        background-color: white;
        padding: 20px 0 50px;
        .item {
          width: 100%;
          display: flex;
          flex-flow: column;
          align-items: flex-start;
          padding: 10px 0;
          border-bottom: solid 1px #f1f5f9;
          .name {
            // width: 50%;
            font-weight: normal;
            font-size: 14px;
            color: var(--color-6);
            margin-bottom: 2px;
            @media screen and(max-width:540px) {
              font-size: 10px;
            }
          }
          .description {
            // width: 50%;
            text-align: left;
            font-weight: 500;
            font-size: 14px;
            color: var(--color-4);
            line-height: 1.2;
            @media screen and(max-width:540px) {
              font-size: 10px;
            }
          }
          .renew-filter {
            width: 100%;
            border-radius: 8px;
            padding: 10px 15px;
            border: solid 1px var(--input-background-color1);
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            .filter-details {
              display: flex;
              flex-flow: column;
              align-items: flex-start;
              flex-grow: 1;
              .filter-heading {
                text-align: left;
                font-weight: 500;
                font-size: 14px;
                color: var(--color-4);
                line-height: 1.2;
              }
              .filter-subheading {
                text-align: left;
                font-weight: normal;
                font-size: 12px;
                color: var(--color-6);
              }
            }
            [nz-icon] {
              font-size: 12px;
              color: var(--color-6);
            }
          }
        }
      }
    }
    .form-modal-wider {
      width: 550px;
      @media screen and(max-width:540px) {
      }
    }
    .form-modal-widest {
      width: 650px;
      @media screen and(max-width:540px) {
        width: 90vw;
      }
    }
  }
}

.error-message {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0 5px 0;
  .error-icon {
    width: 20px;
    img {
      width: 100%;
    }
  }
  .note {
    color: #e02424;
    font-size: 14px;
    text-align: left;
    margin-left: 8px;
    font-weight: 400;
  }
}
.empty-message {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  color: var(--color-6);
  font-size: 14px;
  font-style: italic;
  text-align: center;
}
.document-preview {
  .pdfViewer.removePageBorders .page {
    margin: 0 !important;
  }
}
.custom-shadow {
  box-shadow: 0px 0px 25px 0px #0000001f;
}
.custom-shadow2 {
  box-shadow: 0px 4px 30px 0px #6875821a;
}
input {
  padding: 0 16px !important;
  appearance: none !important;
  margin: 0 !important;
  appearance: none !important; /* Disable default browser styles */
  font-size: inherit !important;
}

.ant-switch {
  background: lightgray;
}
.ant-switch-checked {
  background: #034ea2;
}
.shadow_ {
  box-shadow: 0px 4px 30px 0px #6875821a;
}
@media screen and(max-width:640px) {
  .shadow_ {
    box-shadow: none;
  }
}
// iframe {
//   pointer-events: none;
// }
// .cdk-overlay-container{
//   display: none !important;
// }